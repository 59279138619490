export const ROUTES_PATH = {
  HOME: '/',
  // NAVBAR ROUTES
  PAYMENTS: '/payments',
  TREASURY: '/treasury',
  INVESTMENT_PLANNER: '/investment-planner',
  SAVED_PORTFOLIO: '/investment-planner/saved-portfolio',
  MY_INVESTMENTS: '/my-investments',
  ACCOUNTS: '/treasury-accounts',
  TRANSACTIONS: '/transactions',
  REPORTS: '/reports',
  RECIPIENTS: '/recipients',
  PAYMENT_REQUESTS: '/payment-requests',
  CARDS: '/cards',
  ACCOUNTS_V2: '/accounts',
  // PROMPT ROUTES
  BANKING: '/prompt/banking',
  // OTHER ROUTES
  INVESTMENT_CATALOG: '/investment-catalog',
  RECOMMENDATIONS: '/investment-catalog#our-recommendations',
  INVESTED_ASSET_DETAILS: '/investment-catalog/invested/details/',
  INVESTED_ASSET_STATS: '/investment-catalog/invested/',
  QUESTIONNAIRE: '/quiz',
  RECOMENDATION_ASSET_DETAILS: '/investment-catalog/our-recommendations/details/',
  SIGNUP: '/auth/signup',
  INVEST: '/invest/',
  LOGIN: '/auth/login',
  PRIVACY_POLICY: 'https://www.zamp.finance/privacy-policy',
  HELP_CENTRE: '/help-centre',
  HELP: '/help',
  KYB: '/kyb',
  MAGIC_KYB: '/quick-kyb',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  TERMS_OF_USE: 'https://www.zamp.finance/terms-of-use',
  SETTINGS: '/settings',
  MFA: '/settings/mfa',
  CUSTOM_PORTFOLIO: '/custom-portfolio',
  WITHDRAW_FUNDS: '/withdraw-funds',
  //TODO to prev path /withdraw-funds-v1
  WITHDRAW_FUNDS_V1: '/withdraw-funds',
  FAQ: '/faq',
  CONTACT_US: '/contact-us',
  CONSENT: '/consent',
  ONE_ACCOUNTS: '/new-accounts',
  SELL: '/sell-asset',
  INVITE: '/invite',
  QUIZ: '/quiz',
  MMF_DETAILS: '/mmf-details',
  CASH_SWEEP: '/accounts/cash-sweep',
  COMPANY_DETAILS: '/company-details',
  ROLES: '/roles',
  CUSTOM_ROLE_CREATE: '/roles/create',
  TEAM: '/team',
  TEAM_INVITE: '/team/invite',
  POLICIES: '/policies',
  POLICIES_CREATE: '/policies/create',
  PAYMENT_REQUESTS_CREATE: '/payment-requests/create',
  INITIATE_OKTA: '/auth/initiate/okta',
  SHAREABLE_FORM: '/form/[uid]',
  DEPOSITORS: '/depositors',
  TERMS_OF_USE_ROMA: 'https://www.roma.global/terms-of-use',
  PRIVACY_POLICY_ROMA: 'https://www.roma.global/privacy-policy',
  OTC_HOME: '/otc/home',
  OTC_ALL_ORDERS: '/otc/all-orders',
  OTC_ACCOUNTS: '/otc/accounts',
  OTC_REPORTS: '/otc/reports',
  OTC_RECIPIENTS: '/otc/recipients',
  BANKING_HOME: '/home',
  OTC_TRANSACTIONS: '/otc/transactions',
};

export const PUBLIC_ROUTES = {
  [ROUTES_PATH.INVITE]: true,
  [ROUTES_PATH.SIGNUP]: true,
  [ROUTES_PATH.LOGIN]: true,
  [ROUTES_PATH.INITIATE_OKTA]: true,
  [ROUTES_PATH.SHAREABLE_FORM]: true,
};

export enum ROUTE_KEYS {
  ROOT = 'ROOT',
  HOME = 'HOME',
  PAYMENTS = 'PAYMENTS',
  TREASURY = 'TREASURY',
  INVESTMENT_PLANNER = 'INVESTMENT_PLANNER',
  MY_INVESTMENTS = 'MY_INVESTMENTS',
  ACCOUNTS = 'ACCOUNTS',
  TRANSACTIONS = 'TRANSACTIONS',
  REPORTS = 'REPORTS',
  RECIPIENTS = 'RECIPIENTS',
  PAYMENT_REQUESTS = 'PAYMENT_REQUESTS',
  CARDS = 'CARDS',
  ACCOUNTS_V2 = 'ACCOUNTS_V2',
  BANKING = 'BANKING',
  DEPOSITORS = 'DEPOSITORS',
  OTC_HOME = 'OTC_HOME',
  OTC_TRANSACTIONS = 'OTC_TRANSACTIONS',
  OTC_ACCOUNTS = 'OTC_ACCOUNTS',
  OTC_REPORTS = 'OTC_REPORTS',
  OTC_RECIPIENTS = 'OTC_RECIPIENTS',
  OTC_ALL_ORDERS = 'OTC_ALL_ORDERS',
}

export const LOGIN_URLS = [ROUTES_PATH.LOGIN, ROUTES_PATH.SIGNUP, ROUTES_PATH.INVITE, ROUTES_PATH.INITIATE_OKTA];
