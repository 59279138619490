import { Dispatch, SetStateAction } from 'react';
import { Label } from 'destiny/dist/components/molecules/label';
import { MenuOption } from 'destiny/dist/components/molecules/menuOption';
import { Tooltip } from 'destiny/dist/components/molecules/tooltip/index';
import { COLORS } from 'destiny/dist/constants/colors';
import { TooltipPositions } from 'destiny/dist/constants/molecules/tooltip';
import { getOffRampCurrencies, getOnRampCurrencies, PRODUCT_TYPES_LIST } from 'modules/intent/intent.constants';
import { OnOffRampConfig } from 'modules/intent/intent.types';
import OnOffRampConfigSelector from 'modules/intent/OnOffRampConfigSelector';
import { PRODUCT_TYPES } from 'types';
import { CompanyDetailsEntityType } from 'types/merchantApi.types';
import { trackMixpanel } from 'utils/mixpanel';
import { COUNTRY_OPTIONS } from 'components/country-picker/countries';
import CountryLabel from 'components/CountryLabel';

interface EntityIntentRowProps {
  details: CompanyDetailsEntityType;
  onIntentClick: (product: PRODUCT_TYPES) => void;
  value: PRODUCT_TYPES[];
  merchantName?: string;
  onRampConfig: OnOffRampConfig;
  offRampConfig: OnOffRampConfig;
  setOnRampConfig: Dispatch<SetStateAction<OnOffRampConfig>>;
  setOffRampConfig: Dispatch<SetStateAction<OnOffRampConfig>>;
  isOnRampSelected: boolean;
  isOffRampSelected: boolean;
  setIsOnRampSelected: Dispatch<SetStateAction<boolean>>;
  setIsOffRampSelected: Dispatch<SetStateAction<boolean>>;
  isIndividual: boolean;
}

const EntityIntentRowV2: React.FC<EntityIntentRowProps> = ({
  details,
  onIntentClick,
  value,
  merchantName,
  onRampConfig,
  offRampConfig,
  setOnRampConfig,
  setOffRampConfig,
  isOnRampSelected,
  isOffRampSelected,
  setIsOnRampSelected,
  setIsOffRampSelected,
  isIndividual,
}) => {
  const countryDetails = COUNTRY_OPTIONS?.find((each) => each.code === details?.geography);

  const noProductActive = !details?.offered_product_ids?.length;

  return (
    <div className='tw-bg-BASE_SECONDARY tw-items-center tw-rounded-lg tw-px-4 tw-py-3.5' key={details?.geography}>
      <CountryLabel details={details} merchantName={merchantName} className='tw-mb-6' />

      {noProductActive ? (
        <div className='f-12-300 tw-text-TEXT_SECONDARY'>We are yet to make our service available at this location</div>
      ) : (
        <div className='tw-px-9'>
          {!isIndividual && (
            <>
              <div className='f-14-400 tw-text-TEXT_SECONDARY tw-mb-4'>Products Interested in</div>
              <div className='tw-space-y-3'>
                {PRODUCT_TYPES_LIST.map((eachProduct) => {
                  const isSelected = value?.includes(eachProduct?.value);

                  return (
                    <Tooltip
                      disabled={details?.offered_product_ids?.includes(eachProduct.value)}
                      position={TooltipPositions.TOP}
                      tooltipBody={`${eachProduct?.label} is not available in ${countryDetails?.name} at this time. We are working hard
                      to make it available and hope to onboard you as soon as possible`}
                      key={eachProduct?.value}
                      color={COLORS.TEXT_PRIMARY}
                      tooltipBodystyle='f-12-300 !tw-py-2 !tw-px-3 !tw-rounded tw-w-48 tw-min-w-[192px]'
                    >
                      <MenuOption
                        eventCallback={trackMixpanel}
                        label={
                          <Label
                            title={eachProduct?.label}
                            description={eachProduct?.description}
                            titleClass='f-13-500 tw-mb-1 tw-uppercase'
                          />
                        }
                        isMulti
                        innerProps={{ id: eachProduct?.value }}
                        wrapperClass={`tw-border tw-flex tw-bg-white tw-rounded-2.5 tw-shadow-midShadow ${
                          isSelected ? 'tw-border-TEXT_TERTIARY' : ''
                        }`}
                        contentWrapper='tw-py-4 tw-w-full tw-pr-4'
                        onClick={() => onIntentClick(eachProduct?.value)}
                        isSelected={isSelected}
                        disabled={!details?.offered_product_ids?.includes(eachProduct?.value) ?? false}
                        labelOverrideClassName=''
                      />
                    </Tooltip>
                  );
                })}
              </div>
            </>
          )}
          {(value?.includes(PRODUCT_TYPES.USD_BANKING) ||
            (value?.includes(PRODUCT_TYPES.OTC_DESK) && isIndividual)) && (
            <div className='tw-space-y-4'>
              <div className='f-14-400 tw-text-TEXT_SECONDARY tw-mb-4 tw-mt-8'>Select currencies of trade</div>
              <OnOffRampConfigSelector
                id='ON'
                title={isIndividual ? 'For converting Fiat currencies to Crypto (Onramp)' : 'For On-ramp'}
                currencies={getOnRampCurrencies(isIndividual)}
                config={onRampConfig}
                setConfig={setOnRampConfig}
                isSelected={isOnRampSelected}
                setIsSelected={setIsOnRampSelected}
                isIndividual={isIndividual}
              />
              <OnOffRampConfigSelector
                id='OFF'
                title={isIndividual ? 'For converting Crypto to Fiat currencies (Offramp)' : 'For Off-ramp'}
                currencies={getOffRampCurrencies(isIndividual)}
                config={offRampConfig}
                setConfig={setOffRampConfig}
                isSelected={isOffRampSelected}
                setIsSelected={setIsOffRampSelected}
                isIndividual={isIndividual}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EntityIntentRowV2;
