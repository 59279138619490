import { CRYPTO_PAYMENT_GATEWAY, ON_OFF_RAMP, USD_BUSINESS_BANKING } from 'constants/icons';
import { OptionsType } from 'destiny/dist/types/dropdown';
import { ProductInformationType, ProductListItemType } from 'modules/intent/intent.types';
import { PRODUCT_TYPES } from 'types';

export const PRODUCT_INFORMATION: { [key in PRODUCT_TYPES]?: ProductInformationType } = {
  [PRODUCT_TYPES.USD_BANKING]: {
    title: 'Digital Asset Enabled USD Banking',
    details: [
      {
        title: 'USD Checking Account',
        description: 'Get a unique USD checking account with a local US Bank',
      },
      {
        title: 'Secure Digital Asset Custody',
        description: 'Hold 50+ digital assets with regulated crypto custodian',
      },
      {
        title: 'Off-ramp & On-ramp',
        description: 'Effortlessly convert between Fiat and Digital Assets with confidence, just a few clicks away',
      },
      {
        title: 'Real-time Payments in USD, INR, EUR & Digital Assets',
        description:
          'Make payments to vendors and employees in USD, INR, EUR through local and international rails or in 50+ digital currencies using blockchain rails',
      },
    ],
    icon: USD_BUSINESS_BANKING,
  },
  [PRODUCT_TYPES.OTC_DESK]: {
    title: 'OTC desk',
    details: [
      {
        title: '',
        description:
          'Access exclusive deep liquidity for trading across multiple market pairs with competitive spreads',
      },
      {
        title: '',
        description: 'Seamless and fast trade execution via chat, dashboard or APIs',
      },
      {
        title: '',
        description: 'Trading pairs: USD/AED/EUR to USDT/USDC/BTC/...',
      },
    ],
    icon: ON_OFF_RAMP,
  },
  [PRODUCT_TYPES.CRYPTO_PAYMENT_GATEWAY]: {
    title: 'Crypto Payment Gateway',
    details: [
      {
        title: '',
        description: 'Seamless accept crypto from your customers, merchants and get settled in Fiat',
      },
      {
        title: '',
        description: 'Secure payment gateway link where your customers can pay in 50+ digital currencies',
      },
    ],
    icon: CRYPTO_PAYMENT_GATEWAY,
  },
};

export const PRODUCT_TYPES_LIST: ProductListItemType[] = [
  {
    label: 'Digital Asset Enabled USD Banking',
    description:
      'Get USD Bank accounts, payments in fiat or digital assets, seamless currency conversion & digital assets custody all in one platform',
    value: PRODUCT_TYPES.USD_BANKING,
  },
  {
    label: 'OTC Desk',
    value: PRODUCT_TYPES.OTC_DESK,
    description: 'Execute large orders through our premium OTC trading desk (for trades > 100k USD)',
  },
  {
    label: 'Crypto Payment Gateway',
    value: PRODUCT_TYPES.CRYPTO_PAYMENT_GATEWAY,
    description: 'Accept Crypto from your customers and get settled in Fiat',
  },
];

export const ON_RAMP_CURRENCIES = ['AED', 'USD', 'EUR'];
export const OFF_RAMP_CURRENCIES = ['USD', 'EUR', 'INR', 'AED'];
export const ON_RAMP_CURRENCIES_FOR_INDIVIDUAL = ['AED', 'USD'];
export const OFF_RAMP_CURRENCIES_FOR_INDIVIDUAL = ['AED', 'USD'];

export const MONTHLY_TRADE_VOLUMES: OptionsType[] = [
  { label: 'USD 10,000 - USD 100,000', value: 'USD 10,000 - USD 100,000' },
  { label: 'USD 100,000 - USD 500,000', value: 'USD 100,000 - USD 500,000' },
  { label: 'USD 500,000 - USD 2,000,000', value: 'USD 500,000 - USD 2,000,000' },
  { label: 'USD 2,000,000 - USD 5,000,000', value: 'USD 2,000,000 - USD 5,000,000' },
  { label: 'USD 5,000,000 and above', value: 'USD 5,000,000 and above' },
];

export const MONTHLY_TRADE_VOLUMES_FOR_INDIVIDUAL: OptionsType[] = [
  { label: '0-10K AED', value: '0-10K AED' },
  { label: '10K-20K AED', value: '10K-20K AED' },
  { label: '20K-50K AED', value: '20K-50K AED' },
  { label: '50K-100K AED', value: '50K-100K AED' },
  { label: '>100K AED', value: '>100K AED' },
];

export const getTitle = (isIndividual: boolean) => {
  return isIndividual
    ? `We're excited to have you onboard! Please tell us more about your trading requirements so that we can tailor your experience`
    : `We're excited to have you on board! Please tell us more about your product requirements so we can
                tailor your experience. By default, we've pre-selected all the products that we offer to help you
                get started quickly, but feel free to review and uncheck any that you don't currently need.`;
};

export const getOnRampCurrencies = (isIndividual: boolean) => {
  return isIndividual ? ON_RAMP_CURRENCIES_FOR_INDIVIDUAL : ON_RAMP_CURRENCIES;
};

export const getOffRampCurrencies = (isIndividual: boolean) => {
  return isIndividual ? OFF_RAMP_CURRENCIES_FOR_INDIVIDUAL : OFF_RAMP_CURRENCIES;
};

export const getMonthlyTradeVolumes = (isIndividual: boolean) => {
  return isIndividual ? MONTHLY_TRADE_VOLUMES_FOR_INDIVIDUAL : MONTHLY_TRADE_VOLUMES;
};
