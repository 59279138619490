import { HOUR_GLASS_ICON, KYB_STEP1, KYB_STEP2, KYB_STEP3, KYB_STEP4 } from 'constants/icons';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { KybStatusV2Props } from 'components/kyb/KybStatusV2';

export enum NextButtonLabel {
  NEXT = 'Next',
  SUBMIT = 'Submit',
  CLOSE = 'Close',
  DONE = 'Done',
}

export enum FormFieldType {
  TEXT = 'text',
  NUMBER = 'number',
  SELECT = 'select',
  RADIO = 'radio',
  FILE = 'file',
  SLIDER = 'slider',
  URL = 'url',
  HEADING = 'heading',
  UNORDERED_LIST = 'unordered_list',
  REGION_PICKER = 'region_picker',
  INFO = 'info',
  DATE_PICKER = 'date-picker',
  LIQUIDITY_REQUIRED = 'liquidity_required',
  TEXT_AREA = 'text_area',
  MULTI_SELECT = 'multi_select',
  TABS = 'tabs',
  RADIO_LIST = 'radio-list',
  HIDDEN = 'hidden',
  PHONE_NUMBER = 'phone_number',
  CHECKBOX = 'checkbox',
  BORDERLESS_TEXT = 'borderless_text',
}

// Maintain order of this enum. Used for sorting product chips
export enum StatusType {
  EXPIRED = 'expired',
  FAILED = 'failed',
  NOT_INITIATED = 'not_initiated',
  INITIATED = 'initiated',
  INFO_REQUESTED = 'informated_requested',
  SUCCESS = 'succeeded',
}

export const KYB_PENDING_IMAGE = null;
export const KYB_PENDING_TITLE = 'Last step before you start investing';
export const KYB_PENDING_SUBTITLE = 'Complete your account verification in 5 minutes to start investing';
export const KYB_PENDING_BUTTON_TEXT = 'Activate account';

export const KYB_INITIATED_IMAGE = HOUR_GLASS_ICON;
export const KYB_INITIATED_TITLE = 'Account verification in progress';
export const KYB_INITIATED_SUBTITLE = 'Thanks for submitting the details, your verification will be complete in 24hrs';
export const KYB_INITIATED_BUTTON_TEXT = 'Check status';

export const KYB_SUCCESS_IMAGE = null;
export const KYB_SUCCESS_TITLE = 'Congratulations, your account is verified';
export const KYB_SUCCESS_SUBTITLE = 'Now you can start investing and earn upto 5.0% on your idle cash';
export const KYB_SUCCESS_BUTTON_TEXT = 'Close';

export const KYB_ROMA_PENDING_IMAGE = null;
export const KYB_ROMA_PENDING_TITLE = 'Last step before you start';
export const KYB_ROMA_PENDING_SUBTITLE = 'Complete your account verification in 5 minutes to start';
export const KYB_ROMA_SUCCESS_SUBTITLE = 'Now you can start making payments';

export const KYB_SIDEBAR_IMGS = [KYB_STEP1, KYB_STEP2, KYB_STEP3, KYB_STEP4];

export const MAGIC_KYB_EMAILS = [
  'akshay@nokyb.com',
  'raghav_demo@zamp.finance',
  'raghav+1@zamp.finance',
  'raghav+2@zamp.finance',
  'raghav+3@zamp.finance',
  'raghav+4@zamp.finance',
  'raghav+5@zamp.finance',
  'nipun+1@zamp.finance',
  'nipun+2@zamp.finance',
  'nipun+3@zamp.finance',
  'nipun+4@zamp.finance',
  'nipun+5@zamp.finance',
  'amit+1@zamp.finance',
  'amit+2@zamp.finance',
  'amit+3@zamp.finance',
  'amit+4@zamp.finance',
  'amit+5@zamp.finance',
  'rounka+1@zamp.finance',
  'rounak+2@zamp.finance',
  'amit_vsum@zamp.finance',
];

export const HOME_KYB_BANNER_DATA: {
  [key in StatusType]?: { title: string; subTitle: string; buttonTitle: string };
} = {
  [StatusType.NOT_INITIATED]: {
    title: 'Last step before you start',
    subTitle: 'Complete your account verification in 10 minutes to start',
    buttonTitle: 'Activate account',
  },
  [StatusType.INITIATED]: {
    title: 'Account verification in progress',
    subTitle: 'Thank you for submitting the KYB details, we will inform you via email once your account is verified',
    buttonTitle: 'Check status',
  },
  [StatusType.SUCCESS]: {
    title: 'Congratulations, your account is verified',
    subTitle: '',
    buttonTitle: 'Close',
  },
  [StatusType.FAILED]: {
    title: 'Attention required',
    subTitle: 'We could not complete your KYB. Check remarks for more details',
    buttonTitle: 'View Details',
  },
};

export const COUNTRY_KEY = 'country';
export const STATE_KEY = 'state';

export const HOME_KYB_BANNER_DATA_V2 = (
  isOtcEnabled: boolean,
  isPayoutsEnabled: boolean,
  isPaymentsEnabled: boolean,
  isIndividual: boolean
): {
  [key in StatusType]?: KybStatusV2Props;
} => {
  const kybText = isIndividual ? 'KYC' : 'KYB';

  return {
    [StatusType.NOT_INITIATED]: {
      title: isIndividual
        ? `Let's complete the KYC to start trading`
        : `Lets complete the KYB to enable ${isOtcEnabled ? 'OTC trading' : ''} ${
            isOtcEnabled && isPayoutsEnabled ? ' and ' : ''
          } ${isPayoutsEnabled ? 'USD banking' : ''} ${
            isPaymentsEnabled && !isPayoutsEnabled ? 'Cryptos Payment Gateway' : ''
          }`,
      buttonTitle: `Complete ${kybText}`,
    },
    [StatusType.INITIATED]: {
      title: `Your ${kybText} is under process`,
      buttonTitle: '',
      contentClassName: 'tw-bg-BASE_PRIMARY',
      textClassName: 'tw-text-TEXT_PRIMARY',
      iconId: 'hourglass-03',
      iconCategory: ICON_SPRITE_TYPES.TIME,
      iconColor: COLORS.TEXT_SECONDARY,
    },
    [StatusType.INFO_REQUESTED]: {
      title: `Your ${kybText} is under process`,
      buttonTitle: '',
      className: 'tw-bg-kyb-banner-initiated-background',
      contentClassName: 'tw-bg-BASE_PRIMARY',
      textClassName: 'tw-text-TEXT_PRIMARY',
      iconId: 'hourglass-03',
      iconCategory: ICON_SPRITE_TYPES.TIME,
      iconColor: COLORS.TEXT_SECONDARY,
    },
    [StatusType.SUCCESS]: {
      title: `Your ${kybText} has been successfully processed`,
      buttonTitle: 'Close',
      contentClassName: 'tw-bg-GREEN_TERTIARY',
      textClassName: 'tw-text-GREEN_PRIMARY',
      iconId: 'check-circle',
      iconCategory: ICON_SPRITE_TYPES.GENERAL,
      iconColor: COLORS.GREEN_PRIMARY,
      showTrailingIcon: false,
    },
    [StatusType.FAILED]: {
      title: `We could not complete your ${kybText}. Check remarks for more details`,
      buttonTitle: 'View Details',
    },
  };
};
